import React, {useEffect, useState, useContext} from 'react'
import { GlobalState } from '../../../GlobalState'
import axios from 'axios'
import Button from '../../utils/Button'

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ImageCompressor from 'image-compressor.js'
  
const CSS_Input = {
    item: 'w-full h-[50px] border-[1px] border-[#ccc] rounded-md px-[20px] mt-[0px] shadow-md',
    table: 'border-[1px] border-[#bbb] py-[15px] px-[8px] text-center text-[15px]',
    label: 'block mt-[20px] mb-[5px]'
}

const dataDefault = {
    title: '',
    description: '',
    author: '',
    video: '',
    images: '',
    id_file: null
}

export default function UpadteNews({id}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [data, setData] = useState(dataDefault)
    const [news, setNews] = useState([])
    const [callback, setCallback] = state.newsAPI.callback
    const [category] = state.categoryAPI.category
    const [cluster] = state.clusterAPI.cluster
    const [category_child1] = state.category_childAPI.category_child1
    const [answers] = state.answersAPI.answers
    const [isMobile] = state.responsive.isMobile

    const [selectedFile, setSelectedFile] = useState('')
    const [cate, setCate] = useState(0)
    const [content, setContent] = useState('')
    const [dataCluster, setDataCluster] = useState(0)
    const [check_Ans_cate, setCheck_Ans_cate] = useState(false)
    const [dataAnswers, setDataAnswers] = useState(0)
    const [danhmuc_chinh, setdanhmuc_chinh] = useState(0)

    const [check_news_file, setCheck_news_file] = useState(false)

    useEffect(()=>{
        const getNews = ()=>{
            news.forEach(el=>{
                if(id===el.id) {
                    setData({
                        title: el.title,
                        description: el.description,
                        author: el.author,
                        video: el.video,
                        images: el.images,
                        id_file: el.id_file
                    })
                    setContent(el.content)
                    setCate(el.category)
                    setDataCluster(el.cluster)
                    setDataAnswers(el.answers)
                    setdanhmuc_chinh(el.danhmuc_chinh)

                    if(el.category!==null){
                        setCheck_Ans_cate(true)
                    }
                    else {
                        setCheck_Ans_cate(false)
                    }
                }
            })
        }
        getNews()
    },[news])

    useEffect(()=>{
        getDataNews()
    },[id])

    const getDataNews = async ()=>{
        try {
            const res = await axios.get(`/api/get-news-detail-id/${id}`,{
                headers: {Authorization: token}
            })
            setNews(res.data)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUpload = (e) =>{
        const {name, value} = e.target
        setData({...data,[name]:value})
    }
  
    const handleImage = (value) =>{
        // const file = e.target.files[0]
        const file = value
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedFile(reader.result)
            data.images=(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function CompressImage(e) {
        const file = e.target.files[0];
        
        // Kiểm tra kích thước của tệp trước khi nén
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 1MB.')
            return
        }

        var compressor = new ImageCompressor()
    
        compressor.compress(file, {
            quality: file.size < 50000 ? 0.8 : file.size >= 50000 && file.size < 400000 ? 0.4 : file.size >= 400000 && file.size < 800000 ? 0.3 : file.size >= 800000 ? 0.2: null,
            // maxWidth: 100, 
            // maxHeight: 100,
            mimeType: 'image/jpeg',
            success(result) {
                handleImage(result);
            },
            error(e) {
                console.log(e.message);
            },
        })
    }
  
    const handlesubmit = async () => {
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0];
        let safeContent = content.replace(/'/g, "\\'");
        
        try {
            const res = await axios.put(`/api/news/${id}`, {data, category: cate, date_created: formattedDate, content: safeContent, cluster: dataCluster, answers: dataAnswers, danhmuc_chinh: danhmuc_chinh}, {
                headers: {Authorization: token}
            })
            alert(res.data.msg)
            setCallback(!callback)
            
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleEditorChange = (content)=>{
        setContent(content)
    }

    const handleAnsCate = ()=>{
        setCheck_Ans_cate(!check_Ans_cate)
        setDataAnswers(null)
        setCate(null)
    }

    const handleNewsFile = ()=>{
        setCheck_news_file(!check_news_file)
    }

    useEffect(()=>{
        if(data.id_file!==null){
            setCheck_news_file(true)
        }
        else{
            setCheck_news_file(false)
        }
    },[data.id_file])

    const handleImageUploadBefore = (files, info, uploadHandler) => {
        if (!files || !files[0]) {
            console.error('Không có hình ảnh được chọn.');
            return;
        }

        const file = files[0]

        const maxSizeInBytes = 1 * 1024 * 1024  // Giới hạn kích thước file, ví dụ 1MB
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file quá lớn. Vui lòng chọn file nhỏ hơn.');
            return false;
        }

        uploadHandler(file)
        return true;
    }

  return (
    <div className={`w-full pb-[30px]`}>
        <div className={`${isMobile?'flex-wrap w-full':'w-[80%]'} mx-auto flex justify-around items-center`}>
            <div>
                <p className='text-[15px] pb-[10px] font-bold'>Chọn ảnh đại diện:</p>
                <input type="file" name="file" id="file_up" onChange={CompressImage} onClick={e => (e.target.value = null)}
                    className='h-[40px]' />

                <div className='mt-[30px]' style={{display:check_news_file?'none':''}}>
                    <span className='text-[15px] pb-[10px] font-bold'>Loại bài viết giải đáp:</span>
                    <input type='checkbox' name='answers' value={!check_Ans_cate} checked={!check_Ans_cate} onChange={(e)=>handleAnsCate(e.target.value)} 
                        className='w-[18px] h-[18px] ml-[10px] cursor-pointer'/>
                </div>

                <div className='my-[30px]'>
                    <span className='text-[15px] pb-[10px] font-bold'>Loại bài viết File:</span>
                    <input type='checkbox' name='news_file' value={check_news_file} checked={data.id_file} onChange={(e)=>handleNewsFile(e.target.value)} 
                        className='w-[18px] h-[18px] ml-[10px]'/>
                </div>
            </div>

            <div className='w-[300px] h-[194px] bg-slate-200'>
                <img src={selectedFile?selectedFile:data.images} alt="Ảnh đại diện" style={{ maxWidth: '300px' }}
                    className='w-full aspect-[17/11] object-cover' />
            </div>
        </div>

        <div className={`${isMobile?'flex-wrap w-full':'w-[80%]'} mx-auto`}>
            <label className={CSS_Input.label}>Tiêu đề:</label>
            <input type="text" name="title" id="title" placeholder='Tiêu đề' value={data.title} onChange={handleUpload}
                className={CSS_Input.item} />
            
            <label className={CSS_Input.label} style={{display:check_news_file?'':''}}>Mô tả:</label>
            <input type="text" name="description" id="description" placeholder='Mô tả' value={data.description} onChange={handleUpload}
                className={CSS_Input.item} style={{display:check_news_file?'':''}} />

            <label className={CSS_Input.label}>Danh mục chính:</label>
            <select className={check_Ans_cate ? `${CSS_Input.item}` : 'hidden'} value={danhmuc_chinh===null?0:danhmuc_chinh} onChange={(e)=>setdanhmuc_chinh(e.target.value)}> 
                {
                    category_child1.map(c=>{
                        if(data.danhmuc_chinh===c.id){
                            return <option key={c.id} hidden>{c.name}</option>
                        }
                    })
                }
                {
                    category_child1.map(cate=>{
                        if(cate!==0){
                            return <option key={cate.id} value={cate.id}>{cate.name}</option>
                        }
                    })
                }
            </select>
            
            <label className={CSS_Input.label}>Danh mục cấp 1:</label>
            <select className={check_Ans_cate ? `${CSS_Input.item}` : 'hidden'} value={cate===null?0:cate} onChange={(e)=>setCate(e.target.value)}> 
                {
                    category.map(c=>{
                        if(data.category===c.id){
                            return <option key={c.id} hidden>{c.name}</option>
                        }
                    })
                }
                {
                    category.map(cate=>{
                        return <option key={cate.id} value={cate.id}>{cate.name}</option>
                    })
                }
            </select>

            {/* loại bài giải đáp */}
            <select className={!check_Ans_cate ? `${CSS_Input.item}` : 'hidden'} value={dataAnswers===null?0:dataAnswers} onChange={(e)=>setDataAnswers(e.target.value)} style={{display:check_news_file?'none':''}}> 
                <option hidden>Chọn thể loại</option>
                {
                    answers.map(cate=>{
                        return <option key={cate.id} value={cate.id}>{cate.name}</option>
                    })
                }
            </select>

            <label className={CSS_Input.label}>Danh mục cấp 2:</label>
            <select className={CSS_Input.item} value={dataCluster} onChange={(e)=>setDataCluster(e.target.value)}> 
                <option hidden>Chọn danh mục cấp 2</option>
                {
                    cluster.map(c=>{
                        if(data.cluster===c.id){
                            return <option key={c.id} hidden>{c.name}</option>
                        }
                    })
                }
                {
                    cluster.map(clu=>{
                        return <option key={clu.id} value={clu.id}>{clu.name}</option>
                    })
                }
            </select>
            
            <label className={CSS_Input.label}>Tác giả:</label>
            <input type="text" name="author" id="author" placeholder='Tác giả' value={data.author} onChange={handleUpload}
                className={CSS_Input.item} />

            <label className={CSS_Input.label} style={{display:check_news_file?'':'none'}}>ID File:</label>
            <input type="text" name="id_file" id="id_file" placeholder='Nhập ID File' value={data.id_file} onChange={handleUpload}
                className={CSS_Input.item} style={{display:check_news_file?'':'none'}} /> <br/>
            
            <label className={CSS_Input.label} style={{display:check_news_file?'none':''}}>Link video:</label>
            <textarea type='text' name='video' value={data.video} placeholder='Nhập link video' onChange={handleUpload}
                className={`${CSS_Input.item} h-[80px]`} style={{display:check_news_file?'none':''}} />
            
            <label className={CSS_Input.label} style={{display:check_news_file?'none':''}}>Nội dung:</label>
            <div className='w-full h-auto mt-[20px]' style={{display:check_news_file?'none':''}}>
                <SunEditor
                    placeholder='Nhập nội dung...'
                    setOptions={{
                        buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        '/', // Line break
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['table', 'link', 'image'],
                        ['fullScreen', 'showBlocks', 'codeView'],
                        ['preview']
                        ]
                    }}
                    onChange={handleEditorChange}
                    setContents={content}
                    onImageUploadBefore={handleImageUploadBefore}
                />
            </div>
            <Button data={'Cập nhật'} func={handlesubmit} color={'bg-[#3399FF]'} />
        </div>
    </div>
  )
}
